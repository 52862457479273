<template>
  <div class="table-setting">
    <div v-if="rowSelected && rowSelected.length > 0" class="super-selected">
      <span>{{ rowSelected.length }} selected</span>
      <span class="ml-2 d-flex cursor-pointer" @click="deleteMultiple">
        <FeatherIcon type="trash-2"></FeatherIcon>
      </span>
    </div>
    <a-table
      :rowKey="rowKey"
      :rowSelection="isRowSelection ? (type === 'dashboard' ? rowSelectionDashboard : rowSelection) : false"
      :dataSource="innerTableData"
      :columns="columns_props"
      :locale="locale"
      :loading="loading"
      :pagination="{
        current: pagination.current ? pagination.current : current,
        showQuickJumper: pagination.showQuickJumper,
        showSizeChanger: pagination.showSizeChanger,
        total: pagination.total,
        showLessItems: true,
        showTotal: pagination.showTotal ? (total, range) => `${range[0]}-${range[1]} of ${total} items` : () => '',
      }"
      @change="handleChange"
    >
      <!-- FOR CUSTOM COLUMN -->

      <template #keyword="{ record }">
        <span class="keyword-col" :data-original-keyword="record.originalKeyword" :data-type="record.type">
          {{ record.keyword }}
        </span>
      </template>

      <template #keyword_count="{ record }">
        <span v-if="typeof record.count === 'number'">{{ record.count }}</span>
        <a-skeleton v-else :paragraph="false"></a-skeleton>
      </template>

      <template #customTitle>
        <span v-if="rowSelected && rowSelected.length > 0">
          {{ rowSelected.length }}
        </span>
        <span v-else>Some title</span>
      </template>

      <template #source_name="{ record }">
        <div class="name-wrapper">
          <!-- TODO: change -->
          <img class="fp-img" :src="record.profilePictureURL" />
          <div class="right-block">
            <div class="fanpage-name">{{ record.name }}</div>
            <div class="id">id: {{ record.id }}</div>
          </div>
        </div>
      </template>

      <template #user_name="{ record }">
        <div class="name-wrapper">
          <div class="right-block">
            <div class="user-name">{{ record.user_name }}</div>
            <div class="user-email">{{ record._id }}</div>
          </div>
        </div>
      </template>

      <template #username="{ record }">
        <div class="name-wrapper">
          <div class="right-block">
            <div class="user-name">{{ record.username }}</div>
            <div class="user-email">{{ record.email || 'No email' }}</div>
          </div>
        </div>
      </template>

      <template #role="{ record }">
        <div class="name-wrapper">
          <div class="right-block">
            <div v-if="record.role === 'accountmanager'" class="role">Account Manager</div>
            <div v-else-if="record.role === 'domadmin'" class="role">DOM Admin</div>
            <div v-else class="role">{{ record.role }}</div>
          </div>
        </div>
      </template>

      <template #status="{ record }">
        <span class="status-tag">
          <span class="inner-tag" :class="`status-${record.status}`"> {{ record.status }} </span>
        </span>
      </template>

      <template #sentiment="{ record }">
        <div class="sentiment-circle" :class="sentimentClass(record.sentiment)">
          <FeatherIcon :type="sentimentIcon(record.sentiment)" />
        </div>
      </template>

      <template #type="{ record }">
        <span v-if="isTypeTag" class="type-tag">
          <span class="inner-tag" :class="`type-${record.type}`"> {{ record.type }} </span>
        </span>
        <div v-else class="text-column">{{ record.type }}</div>
      </template>

      <template #ig-username="{ record }">
        <div class="text-column">
          {{ record.username }}
        </div>
      </template>

      <template #yt-channel="{ record }">
        <div class="text-column">
          {{ record.channelName }}
        </div>
      </template>
      <template #updated_at="{ record }">
        <div class="text-column">
          <span v-if="isTimeFromNow">{{ convertToTimeFromNow(record.updated_at || record.updatedAt) }}</span>
          <span v-else>{{ dateStr(record.updated_at || record.updatedAt) }}</span>
        </div>
      </template>
      <template #last_active="{ record }">
        <div class="text-column">
          <span v-if="isTimeFromNow">{{ convertToTimeFromNow(record.lastActiveAt) || '-' }}</span>
          <span v-else>{{ dateStr(record.lastActiveAt) || '-' }}</span>
        </div>
      </template>

      <template #created_at="{ record }">
        <div class="text-column">
          <span v-if="isTimeFromNow">{{ convertToTimeFromNow(record.created_at || record.createdAt) }}</span>
          <div v-else class="text-column">{{ dateStr(record.created_at || record.createdAt) }}</div>
        </div>
      </template>

      <template #notification="{ record }">
        <div class="noti-user">
          <a-tooltip title="Report" placement="topLeft" color="#fff">
            <a-button class="circular noti-icon" :class="{ active: record.isAcceptReport }">
              <img :src="require('@/assets/images/icon/bar-chart.svg')" />
            </a-button>
          </a-tooltip>
          <a-tooltip title="Keyword Alert" placement="topLeft" color="#fff">
            <a-button class="circular noti-icon" :class="{ active: record.isAcceptSpecialKeyword }">
              <FeatherIcon type="key" size="10" />
            </a-button>
          </a-tooltip>
          <a-tooltip title="Setting Alert" placement="topLeft" color="#fff">
            <a-button class="circular noti-icon" :class="{ active: record.isAcceptSettingChange }">
              <FeatherIcon type="settings" size="10" />
            </a-button>
          </a-tooltip>
          <!-- <a-tooltip title="Anomaly Alert" placement="topLeft" color="#fff">
            <a-button class="circular noti-icon" :class="{ active: record.isAcceptAnomaly }">
              <FeatherIcon type="alert-circle" size="10" />
            </a-button>
          </a-tooltip> -->
        </div>
      </template>

      <template #action="{ record }">
        <div v-if="record.isCentral" class="actions-btn" style="height: 42px;">
        </div>
        
        <span v-else-if="isActionDropDown" class="actions-btn-more">
          <a-dropdown :trigger="['click']" placement="bottomRight" :getPopupContainer="(trigger) => trigger.parentNode">
            <a-button
              class="circular"
              :class="{ 'button-primary-light': !isTotalActive }"
              style="width: 42px; margin-right: 7px"
            >
              <FeatherIcon type="more-horizontal" size="18" />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item v-for="(action, idx) in actions" :key="idx" @click="toggleAction(action.value, record)">
                  <FeatherIcon :type="action.icon" size="18" />
                  <span style="margin-left: 10px">{{ action.name }}</span>
                </a-menu-item>
                <!-- <a-menu-item>
              <FeatherIcon type="edit" size="18" />
              Edit
            </a-menu-item>
            <a-menu-item>
              <FeatherIcon type="trash-2" size="18" />
              Delete
            </a-menu-item> -->
              </a-menu>
            </template>
          </a-dropdown>
        </span>

        <span v-else class="actions-btn">
          <a-button type="ghost" class="item-btn circular" style="width: 42px" @click="toggleAction('edit', record)">
            <FeatherIcon type="edit" size="18" />
          </a-button>
          <a-button type="ghost" class="item-btn circular" style="width: 42px" @click="toggleAction('delete', record)">
            <FeatherIcon type="trash-2" size="18" />
          </a-button>

          <!-- <a-button type="link" class="item-btn">
        <FeatherIcon type="edit" size="18" />
      </a-button> -->
          <!-- <a-button type="link" class="item-btn">
        <FeatherIcon type="trash-2" size="18" />
      </a-button> -->
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { ref, toRefs, watch, onMounted } from 'vue';
// import api from '@/services/api';
import helper from '@/services/helper';

export default {
  props: {
    type: String,
    rowKey: String,
    isRowSelection: Boolean,
    isActionDropDown: Boolean,
    isTypeTag: Boolean,
    isTimeFromNow: Boolean,
    isTotalActive: Boolean,
    actions: Array,
    currentPage: Number,
    totalRecords: Number,
    tableData: Array,
    columns_props: Array,
    locale: Object,
    loading: Boolean,
    pagination: {
      type: Object,
      default() {
        return {
          showQuickJumper: false,
          showSizeChanger: false,
          total: false,
          showTotal: () => '',
        };
      },
    },
  },
  name: 'TableSetting',
  setup(props, { emit }) {
    const rowSelected = ref([]);
    const innerTableData = ref([]);
    const { tableData } = toRefs(props);
    let current = ref(1);

    const toggleAction = (action, record) => {
      // console.log('toggleAction', action, record);
      // emit
      emit('toggleAction', {
        action,
        record,
      });
    };

    const deleteMultiple = () => {
      toggleAction('deleteMultiple', rowSelected.value);
    };

    const handleChange = (pagination) => {
      current.value = pagination.current;
      emit('change', pagination);
    };

    const dateStr = (date) => {
      if (date) {
        return helper.formatDate(date, 'MMM D, YYYY');
      }
      return '';
    };

    const convertToTimeFromNow = (date) => {
      if (date) {
        return helper.timeFromNow(date);
      }
      return '';
    };

    const sentimentIcon = (score) => {
      if (score != null) {
        if (score > 1) return 'smile';
        if (score < -1) return 'frown';
        return 'meh';
      }
      return 'x-circle';
    };

    const sentimentClass = (score) => {
      let result = 'neutral-bg';
      if (score != null) {
        if (score > 1) result = 'positive-bg';
        else if (score < -1) result = 'negative-bg';
        else result = 'neutral-bg';
      }
      return result;
    };

    const rowSelectionDashboard = {
      selectedRowKeys: rowSelected,
      onChange: (selectedRowKeys, selectedRows) => {
        // TODO fix keyword key to word + type maybe
        // console.log('rowSelection', selectedRowKeys);
        // selectedRowKeys.value = selectedRowKeys;
        // rowSelected.value = selectedRows.length;
        rowSelected.value = selectedRows;
        emit('rowSelectChange', selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.isCentral === true,
      }),
    };

    const rowSelection = {
      selectedRowKeys: rowSelected,
      onChange: (selectedRowKeys) => {
        // TODO fix keyword key to word + type maybe
        // console.log('rowSelection', selectedRowKeys);
        // selectedRowKeys.value = selectedRowKeys;
        // rowSelected.value = selectedRows.length;
        rowSelected.value = selectedRowKeys;
        emit('rowSelectChange', selectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.isCentral === true,
      }),
    };

    watch(tableData, () => {
      innerTableData.value = [];
      rowSelected.value = [];
      setTimeout(() => {
        innerTableData.value = [...tableData.value];
      }, 200);
    });

    onMounted(() => {
      innerTableData.value = [...tableData.value];
    });

    return {
      rowSelection,
      rowSelectionDashboard,
      handleChange,
      dateStr,
      convertToTimeFromNow,
      sentimentIcon,
      sentimentClass,
      rowSelected,
      toggleAction,
      innerTableData,
      deleteMultiple,
      current,
    };
  },
};
</script>

<style lang="scss">
.table-setting {
  position: relative;
  .super-selected {
    position: absolute;
    top: 0;
    left: 75px;
    z-index: 100;
    background-color: #fafafa;
    border-radius: 0 10px 10px 0;
    height: 56px;
    line-height: 56px;
    padding-left: 15px;
    right: 0;
    border: 1px solid #f1f2f6;
    border-left: none;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1364px) {
      top: 10px;
      left: 9%;
      padding-left: 0;
      height: auto;
      border: none;
    }
    @media screen and (max-width: 1107px) {
      left: 8%;
    }
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-radius: 10px 0 0 10px;
    border-left: 1px solid #f1f2f6;
  }
  .ant-table-thead > tr:last-child > th:last-child {
    border-radius: 0 10px 10px 0;
    border-right: 1px solid #f1f2f6;
  }

  .ant-table-thead > tr th {
    font-weight: 500;
    color: #5a5f7d;
    border-top: 1px solid #f1f2f6;
    @media screen and (max-width: 1143px) {
      padding: 16px 14px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 12px;
      &:nth-child(6) {
        font-size: 12px !important;
      }
      .ant-table-column-sorter .ant-table-column-sorter-inner-full {
        margin-top: -0.85em;
        height: 0.5em;
        line-height: 0.5em;
        width: 0.5em;
      }
    }
    @media screen and (max-width: 1057px) {
      padding: 14px 12px;
    }
    @media screen and (max-width: 1100px) {
      padding: 12px 10px;
    }
    @media screen and (max-width: 860px) {
      padding: 10px;
    }
    @media screen and (max-width: 850px) {
      padding: 8px;
    }
    @media screen and (max-width: 750px) {
      padding: 6px;
      font-size: 10px;
      &:nth-child(6) {
        font-size: 10px !important;
      }
    }
    .anticon-filter {
      position: relative;
      margin-left: 0.57142857em;
      height: 1em;
      line-height: 1em;
      width: 1em;
      svg {
        position: relative;
        margin: 0;
        top: 0;
        left: 0;
      }
    }
    .ant-table-header-column {
      max-width: fit-content;
    }
    @media screen and (max-width: 1890px) {
      &:nth-child(6) {
        font-size: 13.5px;
      }
      .anticon-filter {
        margin-left: 0.4em;
        line-height: 2em;
        font-size: 10px;
      }
    }
    @media screen and (max-width: 1286px) {
      .anticon-filter {
        margin-left: 0.2em;
      }
    }
  }

  .ant-table-tbody > tr > td {
    @media screen and (max-width: 1143px) {
      padding: 16px 14px;
    }
    @media screen and (max-width: 1100px) {
      padding: 14px 12px;
    }
    @media screen and (max-width: 1100px) {
      padding: 12px 10px;
    }
    @media screen and (max-width: 860px) {
      padding: 10px;
    }
    @media screen and (max-width: 850px) {
      padding: 8px;
    }
    @media screen and (max-width: 750px) {
      padding: 6px;
    }
    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }

  .ant-checkbox-inner {
    @media screen and (max-width: 1138px) {
      width: 14px;
      height: 14px;
    }
    @media screen and (max-width: 1057px) {
      width: 12px;
      height: 12px;
    }
    @media screen and (max-width: 991px) {
      width: 14px;
      height: 14px;
    }
    @media screen and (max-width: 860px) {
      width: 12px;
      height: 12px;
    }
  }

  .name-wrapper {
    display: flex;
    align-items: flex-start;

    .fp-img {
      display: inline-block;
      vertical-align: middle;
      width: 47px;
      height: 47px;
      min-width: 47px;
      min-height: 47px;
      border-radius: 47px;
      overflow: hidden;
      background-color: #ddd;
      border: 1px solid #ddd;
      margin-right: 0.7rem;
    }

    .right-block {
      display: flex;
      flex-direction: column;
      .file-name {
        font-weight: bold;
        color: #5a5f7d;
        line-height: 15px;
      }
      .file-type {
        color: #9299b8;
        text-transform: uppercase;
      }
      .id {
        color: #9299b8;
      }
      .user-name {
        @media screen and (max-width: 1174px) {
          font-size: 12px;
        }
        @media screen and (max-width: 750px) {
          font-size: 10px;
        }
      }
      .user-email {
        font-size: 12px;
        color: #9299b8;
        white-space: normal;
        @media screen and (max-width: 1200px) {
          word-break: break-word;
        }
        @media screen and (max-width: 1100px) {
          font-size: 10px;
        }
        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
        @media screen and (max-width: 750px) {
          font-size: 8px;
        }
      }
      .role {
        text-transform: capitalize;
        white-space: normal;
        @media screen and (max-width: 1210px) {
          font-size: 12px;
        }
        @media screen and (max-width: 750px) {
          font-size: 10px;
        }
      }
    }
  }

  .text-column {
    color: #5a5f7d;
    text-transform: capitalize;
    @media screen and (max-width: 1210px) {
      font-size: 12px;
    }
    @media screen and (max-width: 750px) {
      font-size: 10px;
    }
  }

  .sentiment-circle {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    padding: 4px;
    // cursor: pointer;
    &.positive-bg {
      background-color: rgba(32, 201, 151, 0.15);
      color: #20c997;
    }
    &.neutral-bg {
      background-color: #fff5e2;
      color: #febc3c;
    }
    &.negative-bg {
      background-color: rgba(255, 77, 79, 0.15);
      color: #ff4d4f;
    }
  }

  .ant-dropdown-menu {
    padding: 10px 0px !important;
  }

  .actions-btn {
    // display: flex;
    // column-gap: 15px;
    .item-btn {
      padding: 0px !important;
      i {
        color: #adb4d2;
      }
    }
  }

  .actions-btn-more {
    .ant-btn {
      @media screen and (max-width: 500px) {
        padding: 0 10px;
        margin-right: 0 !important;
      }
    }
  }

  .ant-btn-ghost:hover,
  .ant-btn-ghost:focus {
    color: #2c99ff;
    background: rgba(44, 153, 255, 0.1) !important;
    border-color: #2c99ff;
    i {
      color: #2c99ff;
    }
  }

  .type-tag {
    font-size: 10px;
    margin-right: 4px;
    white-space: nowrap;
    text-align: center;
    .inner-tag {
      padding: 4px 8px;
      border-radius: 4px;
      text-transform: capitalize;
      &.type-Word,
      &.type-word {
        background: #dff0ff;
        color: #2c99ff;
      }
      &.type-Hashtag,
      &.type-hashtag {
        background: #f9ede1;
        color: #d98736;
      }
      &.type-Username,
      &.type-username {
        background: #f5f9e1;
        color: #a3d936;
      }
    }
  }

  .status-tag {
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
    white-space: nowrap;
    @media screen and (max-width: 1065px) {
      font-size: 10px;
    }
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
    @media screen and (max-width: 750px) {
      font-size: 10px;
    }
    .inner-tag {
      padding: 3px 12px;
      border-radius: 15px;
      @media screen and (max-width: 1138px) {
        padding: 3px 8px;
      }
      @media screen and (max-width: 750px) {
        padding: 3px;
      }
      &.status-Active,
      &.status-active {
        color: #20c997;
        background: #e9faf5;
      }
      &.status-Inactive,
      &.status-inactive {
        color: #fa8b0c;
        background: #fff3e7;
      }
    }
  }

  .noti-user {
    @media screen and (max-width: 1286px) {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }
    @media screen and (max-width: 1030px) {
      flex-direction: row;
      row-gap: 0;
      column-gap: 4px;
    }
    @media screen and (max-width: 795px) {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }
    @media screen and (max-width: 750px) {
      flex-direction: row;
      row-gap: 0;
      column-gap: 4px;
    }
    @media screen and (max-width: 656px) {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
    }
  }

  .noti-icon {
    width: 23px;
    height: 30px;
    margin-right: 4px;
    // color: #097cff;
    // background: rgba(9, 124, 255, 0.1);
    border-radius: 50%;
    background: rgba(173, 180, 210, 0.1);
    color: #adb4d2;
    cursor: auto;
    img,
    i,
    svg {
      width: 12px;
      height: 12px;
    }
    @media screen and (max-width: 1364px) {
      &:nth-child(3) {
        margin-top: 4px;
      }
    }
    @media screen and (max-width: 1286px) {
      margin-right: 0;
      &:nth-child(3) {
        margin-top: 0;
      }
    }
    @media screen and (max-width: 1138px) {
      height: 23px;
      padding: 0 10px;
      img,
      i,
      svg {
        width: 10px;
        height: 10px;
      }
    }
    @media screen and (max-width: 1030px) {
      width: 16px;
      height: 16px;
      padding: 0 5px;
      img,
      i,
      svg {
        width: 8px;
        height: 8px;
      }
    }
    @media screen and (max-width: 991px) {
      width: 23px;
      height: 23px;
      padding: 0 10px;
      img,
      i,
      svg {
        width: 10px;
        height: 10px;
      }
    }
    @media screen and (max-width: 750px) {
      width: 16px;
      height: 16px;
      padding: 0 5px;
      img,
      i,
      svg {
        width: 8px;
        height: 8px;
      }
    }
    &.active {
      background: rgba(9, 124, 255, 0.1);
      img {
        filter: invert(60%) sepia(90%) saturate(1200%) hue-rotate(196deg) brightness(100%) contrast(119%);
      }
      i {
        color: #097cff;
      }
    }
  }

  // .noti-icon:hover {
  //   color: #097cff;
  //   background: rgba(9, 124, 255, 0.1);
  // }
}
</style>
<style scoped>
@media screen and (max-width: 1890px) {
  :deep(.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters) {
    padding-right: 15px !important;
  }
}
@media screen and (max-width: 1364px) {
  :deep(.ant-table-thead > tr > th.ant-table-column-sort) {
    background: #fafafa;
  }
}
</style>
